<template>
  <sub-page
      :title="$t('hr.positions.title')"
      :header-action-text="$t('Add')"
      :header-action="openNewPosition"
      icon="mdi-briefcase-outline"
      v-model="$store.state.settings.pageData"
  >


    <template #default>

      <ws-data-table
          :items="items"
          :headers="headers"
          disable-pagination
          class="mt-5"
          :row-action="(item) => openEditPosition(item)"
      >
        <template #item.salary="{item}">
          <h5 class="text-no-wrap">
            <span v-if="item.salary">
                {{ item.salary }} {{ BUSINESS_CURRENCY}}
            </span>
            <span v-else>
              {{ $t('hr.employee.no_stake') }}
            </span>
          </h5>
        </template>
        <template #item.is_single="{item}">
          <div class="d-flex justify-center mr-3">
            <v-icon v-if="item.is_single" :color="wsACCENT">mdi-checkbox-marked-circle-outline</v-icon>
          </div>
        </template>

        <template #item.action="{item,hover}">
          <div>
            <v-btn icon :color="wsACCENT" @click="openEditPosition(item)" >
              <v-icon v-if="hover">mdi-pencil</v-icon>
            </v-btn>
          </div>
        </template>

      </ws-data-table>


    </template>

    <template #dialog>
      <ws-dialog
          v-if="displayPositionDialog"
          v-model="displayPositionDialog"
          @save="addEditPosition"
          @delete="deletePosition"
          :title="$t('hr.positions.add')"
          :show-delete="!newPosition"


      >
        <ws-text-field
            v-model="PositionData.name"
            :label="$t('Name')"
            :placeholder="$t('EnterName')"
        />
        <ws-text-field
            class="mt-5"
            v-model="PositionData.salary"
            :label="$t('Salary')"
            :placeholder="$t('hr.employee.no_stake')"
            number clearable
        />
        <ws-switch
            class="pt-3"
            v-model="PositionData.is_single"
            :placeholder="$t('hr.positions.is_single.title')"
            :tooltip="$t('hr.positions.is_single.tooltip')"
            number
        />

      </ws-dialog>
    </template>

  </sub-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "hrEmployesAndPosition",
  components : {
  },
  data() {
    return {
      items : [],
      displayPositionDialog : false,
      PositionData : {},
      newPosition : false
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('hr.positions.is_single.title') , value : 'is_single' , width : 10 },
        { text : this.$t('Salary') , value : 'salary' , width : 10 },
        { value : 'action' , width : 10 }
      ]
    }
  },
  methods : {
    ...mapActions('hr', [
      'GET_HR_POSITIONS',
      'ADD_EDIT_HR_POSITION',
      'DELETE_HR_POSITION'
    ]),

    async addEditPosition() {
      let result = await this.ADD_EDIT_HR_POSITION(this.PositionData)
      if ( !result ) {
        return this.ERROR()
      }
      if ( this.newPosition ) {
        this.items.push(result)
      } else {
        let index = this.items.findIndex(el => el.uuid === result.uuid)
        this.items[index] = result
        this.items = this.COPY(this.items)
      }

      this.displayPositionDialog = false
    },
    async deletePosition() {
      let result = await this.DELETE_HR_POSITION(this.PositionData.uuid)
      if ( !result ) {
        return this.ERROR()
      }
      let index = this.items.findIndex(el => el.uuid === this.PositionData.uuid)
      if ( index === -1 ) {
        this.ERROR()
        return
      }
      this.items.splice(index , 1)

      this.displayPositionDialog = false
    },

    openNewPosition() {
      this.newPosition = true
      this.PositionData = {}
      this.displayPositionDialog = true
    },
    openEditPosition(item) {
      this.newPosition = false
      this.PositionData = this.COPY(item)
      this.displayPositionDialog = true
    },

    async initPage() {
      let result = await this.GET_HR_POSITIONS()
      if ( !result ) {
        return this.ERROR()
      }
      this.items = result.items
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>