var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sub-page',{attrs:{"title":_vm.$t('hr.positions.title'),"header-action-text":_vm.$t('Add'),"header-action":_vm.openNewPosition,"icon":"mdi-briefcase-outline"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('ws-data-table',{staticClass:"mt-5",attrs:{"items":_vm.items,"headers":_vm.headers,"disable-pagination":"","row-action":function (item) { return _vm.openEditPosition(item); }},scopedSlots:_vm._u([{key:"item.salary",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-no-wrap"},[(item.salary)?_c('span',[_vm._v(" "+_vm._s(item.salary)+" "+_vm._s(_vm.BUSINESS_CURRENCY)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('hr.employee.no_stake'))+" ")])])]}},{key:"item.is_single",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center mr-3"},[(item.is_single)?_c('v-icon',{attrs:{"color":_vm.wsACCENT}},[_vm._v("mdi-checkbox-marked-circle-outline")]):_vm._e()],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
var hover = ref.hover;
return [_c('div',[_c('v-btn',{attrs:{"icon":"","color":_vm.wsACCENT},on:{"click":function($event){return _vm.openEditPosition(item)}}},[(hover)?_c('v-icon',[_vm._v("mdi-pencil")]):_vm._e()],1)],1)]}}])})]},proxy:true},{key:"dialog",fn:function(){return [(_vm.displayPositionDialog)?_c('ws-dialog',{attrs:{"title":_vm.$t('hr.positions.add'),"show-delete":!_vm.newPosition},on:{"save":_vm.addEditPosition,"delete":_vm.deletePosition},model:{value:(_vm.displayPositionDialog),callback:function ($$v) {_vm.displayPositionDialog=$$v},expression:"displayPositionDialog"}},[_c('ws-text-field',{attrs:{"label":_vm.$t('Name'),"placeholder":_vm.$t('EnterName')},model:{value:(_vm.PositionData.name),callback:function ($$v) {_vm.$set(_vm.PositionData, "name", $$v)},expression:"PositionData.name"}}),_c('ws-text-field',{staticClass:"mt-5",attrs:{"label":_vm.$t('Salary'),"placeholder":_vm.$t('hr.employee.no_stake'),"number":"","clearable":""},model:{value:(_vm.PositionData.salary),callback:function ($$v) {_vm.$set(_vm.PositionData, "salary", $$v)},expression:"PositionData.salary"}}),_c('ws-switch',{staticClass:"pt-3",attrs:{"placeholder":_vm.$t('hr.positions.is_single.title'),"tooltip":_vm.$t('hr.positions.is_single.tooltip'),"number":""},model:{value:(_vm.PositionData.is_single),callback:function ($$v) {_vm.$set(_vm.PositionData, "is_single", $$v)},expression:"PositionData.is_single"}})],1):_vm._e()]},proxy:true}]),model:{value:(_vm.$store.state.settings.pageData),callback:function ($$v) {_vm.$set(_vm.$store.state.settings, "pageData", $$v)},expression:"$store.state.settings.pageData"}})}
var staticRenderFns = []

export { render, staticRenderFns }